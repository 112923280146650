import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const getUsersActivityReport = createAsyncThunk(
  "/reports/usersActivityReport",
  async (
    params: {
      page: number;
      per_page: number;
      selectedUsers: number[];
      selectedRoles: number[];
      month: number;
      year: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/usersActivityReport`,
        "POST",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/reports/usersActivityReport", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getUsersExtraActivities = createAsyncThunk(
  "/reports/usersExtraActivities",
  async (
    params: {
      page: number;
      per_page: number;
      selectedUsers: number[];
      month: number;
      year: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/usersExtraActivities`,
        "POST",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/reports/usersExtraActivities", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
