import React from "react";
import Checkbox from "../../../components/misc/Checkbox";
import { getDateAndTime } from "../../../utils/dateAndTime";
import { SubRouteDataInterface } from "../../../redux/interfaces/admin.interface";

function UserPermissionsTable({
  permissions,
  handleCheckRoute,
}: {
  permissions: SubRouteDataInterface[];
  handleCheckRoute: (routeId: number, checked: boolean) => void;
}) {
  return (
    <table className="table table-row-bordered">
      <thead>
        <tr>
          <th scope="col">Permisiune</th>
          <th scope="col">Admin</th>
          <th scope="col">Data</th>
        </tr>
      </thead>
      <tbody>
        {permissions.map((route) => {
          return (
            <tr key={route.idRoute}>
              <td width={"75%"}>
                <Checkbox
                  label={route.routeLabel}
                  name={`${route.idRoute}`}
                  labelAlign="right"
                  onChange={(checked) =>
                    handleCheckRoute(route.idRoute, checked)
                  }
                  checked={route.isChecked}
                  withFullWidth={false}
                />
              </td>
              <td width={"15%"}>
                {route.userName ? `${route.userName} (${route.idAdmin})` : "-"}
              </td>
              <td width={"10%"}>
                {route.userName ? getDateAndTime(route.addedAt) : "-"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default UserPermissionsTable;
