import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ContentCard from "../../../components/misc/ContentCard";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchUserPermissionsWeb,
  postUserPermissionsWeb,
} from "../../../redux/api/admin.api";
import CustomButton from "../../../components/CustomButton";
import { PermissionsWebDataInterface } from "../../../redux/interfaces/admin.interface";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import UserPermissionsTable from "../components/UserPermissionsTable";

function AdministrateUserWebRoles() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeParams } = useRoutesHelper();

  const {
    admin: {
      permissionsWeb: { data: permissionsData, isLoadingPermissionsWeb },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const { userId, userName } = routeParams || {};

  const [permissions, setPermissions] = useState<PermissionsWebDataInterface[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserPermissionsWeb(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (permissionsData && permissionsData.length > 0) {
      setPermissions(permissionsData);
    }
  }, [permissionsData]);

  const handleCheckSubRoute = (subrouteId: number, isChecked: boolean) => {
    const newPermissions = permissions.map((permission) => {
      const newSubRoutes = permission.subRoutes.map((subRoute) => {
        if (subRoute.idRoute === subrouteId) {
          return {
            ...subRoute,
            isChecked,
          };
        }
        return subRoute;
      });

      return {
        ...permission,
        subRoutes: newSubRoutes,
      };
    });
    setPermissions(newPermissions);
  };

  const handleCheckPrimaryRoute = (routeId: number) => {
    const newPermissions = permissions.map((permission) => {
      if (permission.idRoute === routeId) {
        const isAllChecked = permission.subRoutes.every(
          (subRoute) => subRoute.isChecked,
        );

        const newSubRoutes = permission.subRoutes.map((subRoute) => {
          return {
            ...subRoute,
            isChecked: isAllChecked ? false : true,
          };
        });
        return {
          ...permission,
          subRoutes: newSubRoutes,
        };
      }

      return permission;
    });
    setPermissions(newPermissions);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postUserPermissionsWeb({
        userId,
        permissions: permissions,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postUserPermissionsWeb) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postUserPermissionsWeb) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  return (
    <ContentCard
      cardTitle={`Editeaza roluri utilizator: ${userName}`}
      isLoading={isLoadingPermissionsWeb}>
      {permissions.map((permission) => {
        console.log("permission.subRoutes", permission);

        return (
          <div key={permission.idRoute} className="mb-8 mt-4">
            <CustomButton
              className="mb-4"
              variant="contained"
              size="small"
              onClick={() => handleCheckPrimaryRoute(permission.idRoute)}>
              {permission.routeName}
            </CustomButton>
            <UserPermissionsTable
              permissions={permission.subRoutes}
              handleCheckRoute={handleCheckSubRoute}
            />
          </div>
        );
      })}

      <div className="d-flex justify-content-between">
        <CustomButton type="submit" variant="outlined" onClick={handleGoBack}>
          Anuleaza
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}>
          Salveaza
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default AdministrateUserWebRoles;
