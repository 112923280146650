import { createSlice } from "@reduxjs/toolkit";

import {
  UsersActivityDataInterface,
  UsersExtraActivitiesDataInterface,
} from "../interfaces/reports.interface";
import {
  getUsersActivityReport,
  getUsersExtraActivities,
} from "../api/reports.api";

export const initialReportsState: {
  usersActivity: UsersActivityDataInterface;
  usersExtraActivities: UsersExtraActivitiesDataInterface;
} = {
  usersActivity: {
    data: {
      total: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  usersExtraActivities: {
    data: {
      total: 1,
      data: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const reportsReducer = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {},
  extraReducers: (builder) => {
    // users activity raport start
    builder.addCase(getUsersActivityReport.pending, (state) => {
      return {
        ...state,
        usersActivity: {
          ...initialReportsState.usersActivity,
          isLoading: true,
        },
      };
    });
    builder.addCase(getUsersActivityReport.rejected, (state, action: any) => {
      return {
        ...state,
        usersActivity: {
          ...state.usersActivity,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getUsersActivityReport.fulfilled, (state, action) => {
      return {
        ...state,
        usersActivity: {
          ...state.usersActivity,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // users activity raport end

    // users extra activities raport end start
    builder.addCase(getUsersExtraActivities.pending, (state) => {
      return {
        ...state,
        usersExtraActivities: {
          ...initialReportsState.usersExtraActivities,
          isLoading: true,
        },
      };
    });
    builder.addCase(getUsersExtraActivities.rejected, (state, action: any) => {
      return {
        ...state,
        usersExtraActivities: {
          ...state.usersExtraActivities,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getUsersExtraActivities.fulfilled, (state, action) => {
      return {
        ...state,
        usersExtraActivities: {
          ...state.usersExtraActivities,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // users extra activities raport end
  },
});

// export const {} = reportsReducer.actions;

export default reportsReducer.reducer;
